import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Api from '../../services/api.service';
import { listToMatrix } from '../../services/utils.service';

import {
  Wrapper,
  MonthYearText,
  WeekdaysRow,
  Weekday,
  CalendarGrid,
  CalendarCell,
  CalendarInfoRow,
  CalendarInfo,
  SummaryWrapper,
  Row,
  Column,
  ManifestationStateIndicator
} from './app-calendar.style';

const moment = extendMoment(Moment);

class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);
    const date = props.date || moment();
    const year = date.year(); 
    const month = date.month();
    const c = this.getCalendar(year, month);
    this.state = {
      loading: false,
      month,
      year,
      weekIndex: this._findWeekIndex(c, date),
      calendar: c,
      manifestations: {}
    };
  }

  componentDidMount() {
    this.fetchManifestations();
  }

  fetchManifestations = async (firstDay, endDay) => {
    const { calendar: c } = this.state;
    const { userId } = this.props;
    if (firstDay === undefined) firstDay = c[0][0];
    if (endDay === undefined) endDay = c[c.length-1][6];
    this.setState({ loading: true });
    try {
      let events = [];
      let page = 1;
      while (true) {
        const { data } = await Api.listEvent(userId, firstDay, endDay, page);
        if (typeof data === 'string') break;
        events = events.concat(data.data);
        if (data.next_page_url === null) break;
        page++;
      }
      
      const manifestations = {
        confirmedCount: 0,
        shiftedCount: 0,
        concludedCount: 0,
        canceledCount: 0
      };
      
      events.forEach((event) => {
        const date = event.date.split(' ')[0];

        if (event.state === 'draft' && event.change_date !== undefined) {
          event.state = 'shifted';
        }

        if (manifestations[date] === undefined) manifestations[date] = [];
        manifestations[date].push(event);
        manifestations[`${event.state}Count`]++;
      });

      this.setState({ manifestations });
    } finally {
      this.setState({ loading: false });
    }
  };

  getCalendar = (year, month) => {
    const startDate = moment([year, month]);
    let firstDay = moment(startDate).startOf('month');
    let endDay = moment(startDate).endOf('month');

    if (startDate.isoWeekday() !== 7) {
      firstDay = firstDay.subtract(1, 'week').isoWeekday('Sunday');
    }
    if (endDay.isoWeekday() !== 6) {
      endDay = endDay.isoWeekday('Saturday');
    }

    const monthRange = moment.range(firstDay, endDay);
    const days = Array.from(monthRange.by('days'));
    return listToMatrix(days, 7);
  }

  _findWeekIndex = (calendar, day) => {
    const lastIndex = calendar.length-1
    for (let weekIndex=0; weekIndex < lastIndex; weekIndex++) {
      if (day.isBefore(calendar[weekIndex+1][0])) return weekIndex;
    }
    return lastIndex;
  };

  selectDay = (day, flag=true) => {
    const { onChange=()=>{} } = this.props;

    if (flag) {
      const { year, month } = this.state;
      const newYear = day.year();
      const newMonth = day.month();

      if (newYear !== year || newMonth !== month) {
        const calendar = this.getCalendar(newYear, newMonth);
        this.setState({ 
          year: newYear, 
          month: newMonth, 
          weekIndex: this._findWeekIndex(calendar, day), 
          calendar 
        });
        this.fetchManifestations(calendar[0][0], calendar[calendar.length-1][6]);
      }
    }

    this.setState({ weekIndex: this._findWeekIndex(this.state.calendar, day) });
    onChange(day);
  }

  nextDay = () => this.selectDay(moment(this.props.date).add(1, 'days'));
  previousDay = () => this.selectDay(moment(this.props.date).add(-1, 'days'));

  // next month update week index too
  nextWeek = () => {
    const { calendar, weekIndex } = this.state;
    const nextWeek = weekIndex+1;
    if (nextWeek >= calendar.length) {
      this.nextMonth();
    } else {
      this.setState({ weekIndex: nextWeek });
    }
  };

  // previous month update week index too
  previousWeek = () => {
    const { weekIndex } = this.state;
    const nextWeek = weekIndex-1;
    if (nextWeek < 0) {
      this.previousMonth();
    } else {
      this.setState({ weekIndex: nextWeek });
    }
  };

  nextMonth = () => {
    let month, year;
    if (this.state.month === 11) {
      month = 0;
      year = this.state.year + 1;
    } else {
      month = this.state.month + 1;
      year = this.state.year;
    }

    const c = this.getCalendar(year, month);
    this.fetchManifestations(c[0][0], c[c.length-1][6]);
    this.setState({
      month,
      year,
      calendar: c,
      weekIndex: 0
    });
  };

  previousMonth = () => {
    let month, year;
    if (this.state.month === 0) {
      month = 11;
      year = this.state.year - 1;
    } else {
      month = this.state.month - 1;
      year = this.state.year;
    }

    const c = this.getCalendar(year, month);
    this.fetchManifestations(c[0][0], c[c.length-1][6]);
    this.setState({
      month,
      year,
      calendar: c,
      weekIndex: c.length-1
    });
  };

  countManifestationStates = () => {
    const { calendar, manifestations, weekIndex } = this.state;
    const { date, type } = this.props;

    if (type === 'monthly') {
      return {
        confirmedCount: manifestations.confirmedCount,
        shiftedCount: manifestations.shiftedCount,
        concludedCount: manifestations.concludedCount,
        canceledCount: manifestations.canceledCount
      };
    }

    const counts = {
      confirmedCount: 0,
      shiftedCount: 0,
      concludedCount: 0,
      canceledCount: 0
    };

    if (type === 'weekly') {
      calendar[weekIndex].forEach((day) => {
        const manifestationsDay = manifestations[day.format('DD/MM/YYYY')] || [];
        manifestationsDay.forEach(event => counts[`${event.state}Count`]++);
      });
    } else if (type === 'daily') {
      const manifestationsDay = manifestations[date.format('DD/MM/YYYY')] || [];
      manifestationsDay.forEach(event => counts[`${event.state}Count`]++);
    }

    return counts;
  };

  renderWeek = (index = undefined) => {
    const { calendar, weekIndex, manifestations, month } = this.state;
    const { date, onManifestationEdit } = this.props;
    const week = calendar[index === undefined ? weekIndex : index];
    let selectedManifestations = [];

    const rowCells = week.map(day => {
      const formattedDay = day.format('DD/MM/YYYY');
      const selected = day.isSame(date, 'day');
      if (selected) {
        const uniques = manifestations[formattedDay]?.filter((m, i, self) => self.findIndex(t => t.id === m.id) === i);
        selectedManifestations = uniques || [];
      }

      return (
        <CalendarCell 
          key={formattedDay}
          onClick={() => this.selectDay(day, false)}
          manifestations={manifestations[formattedDay]}
          selected={selected}
          day={day}
          disabledStyle={day.month() !== month}
        />
      );
    });

    return (
      <Fragment key={week}>
        {rowCells}
        <CalendarInfoRow style={selectedManifestations.length > 0 ? {} : { maxHeight: 0, padding: 0 }}>
          
          {selectedManifestations.map(m => (
            <CalendarInfo 
              key={m.id}
              manifestation={m}
              onEdit={() => onManifestationEdit(m)}
            />
          ))}
        </CalendarInfoRow>
      </Fragment>
    );
  };

  render() {
    const { manifestations, loading, month, year, calendar, weekIndex } = this.state;
    const { date, type, onManifestationEdit } = this.props;

    if (loading) {
      return (
        <MonthYearText>Caricamento in corso...</MonthYearText>
      );
    }
    const stateCounts = this.countManifestationStates();
    return (
      <Wrapper>
        <MonthYearText>
          {type === 'daily' ? date.format('DD MMMM YYYY') : moment([year, month]).format('MMMM YYYY')}
        </MonthYearText>
        {type === 'weekly' && <MonthYearText>{weekIndex+1}° settimana</MonthYearText>}
        {type !== 'daily' ? <>
          <WeekdaysRow>
            <Weekday day="DOM" isRed={true} />
            <Weekday day="LUN" />
            <Weekday day="MAR" />
            <Weekday day="MER" />
            <Weekday day="GIO" />
            <Weekday day="VEN" />
            <Weekday day="SAB" />
          </WeekdaysRow>

          <CalendarGrid>
            {type === 'monthly' && calendar.map((_, i) => this.renderWeek(i))}
            {type === 'weekly' && this.renderWeek()}
          </CalendarGrid>
        </> : <DailyList onEdit={onManifestationEdit} manifestations={manifestations[date.format('DD/MM/YYYY')]} /> }

        <SummaryWrapper>
          <Column>
            <Row>
              <p>{stateCounts.confirmedCount}</p>
              <ManifestationStateIndicator state='confirmed' />
              <p>Programmate</p>
            </Row>
            <Row>
              <p>{stateCounts.shiftedCount}</p>
              <ManifestationStateIndicator state='shifted' />
              <p>Spostate</p>
            </Row>
          </Column>
          <Column>
            <Row>
              <p>{stateCounts.concludedCount}</p>
              <ManifestationStateIndicator state='concluded' />
              <p>Effettuate</p>
            </Row>
            <Row>
              <p>{stateCounts.canceledCount}</p>
              <ManifestationStateIndicator state='canceled' />
              <p>Annulate</p>
            </Row>
          </Column>
        </SummaryWrapper>
      </Wrapper>
    );
  }
}

const DailyList = ({ manifestations, onEdit }) => {
  if (!manifestations) {
    return (
      <p style={{ marginTop: 20, fontSize: '1.1em' }}>Non ci sono eventi in programma</p>
    );
  }
  return (
    <div style={{ marginTop: 30 }}>
      {manifestations.map(m => (
        <CalendarInfo 
          key={m.id}
          margin='15px 0'
          textColor='#000000'
          manifestation={m}
          onEdit={() => onEdit(m)}
        />
      ))}
    </div>
  );
};

export default MonthlyCalendar;
