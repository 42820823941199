/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Container from "../../components/container.component";
import GoBack from "../../components/goback.component";
import Paging from "../../components/paging.component";
import Api from "../../services/api.service";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import {
  Wrapper,
  Body,
  SearchWrapper,
  InputText,
  Lens,
  AssociationWrapper,
  AssociationName,
  AssociationPlace,
  RedDots,
  NextDivisor,
  BackToFirst,
  ButtonsWrapper,
  Back,
  Next,
  NextToLast,
  ErrorMessage,
  AddButton,
  TabContainer,
  Tab,
} from "./association_list.style";

function AssociationList() {
  const [error, setError] = useState(false);
  const [results, setResults] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [onlyMine, setOnlyMine] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const searchLoc = useRef();

  const searchLocation = useCallback(async () => {
    setError(false);
    setIsLoading(true);
    try {
      const { data: profile } = await Api.getProfile();
      const res = await Api.searchLocation(
        search,
        page,
        profile.user.id,
        onlyMine
      );
      if (res.data.data.length === 0) {
        const res2 = await Api.searchLocation(
          search,
          res.data.last_page,
          profile.user.id,
          onlyMine
        );
        setResults(res2.data);
        setPage(res.data.last_page);
      } else {
        setResults(res.data);
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [search, page, onlyMine]);

  useEffect(() => {
    searchLocation();
  }, [searchLocation]);

  const lastPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(results.last_page);
  };

  const firstPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(1);
  };

  const openAssociation = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push({
      pathname: "/association",
      state: id,
    });
  };

  const previousPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(page - 1);
  };

  const nextPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(page + 1);
  };

  return (
    <>
      <GoBack />
      <Body>
        <Container
          title={"Elenco Associazioni"}
          content={
            <>
              <Wrapper>
                {error && (
                  <ErrorMessage>Nessuna corrispondenza trovata</ErrorMessage>
                )}
                <SearchWrapper>
                  <InputText
                    onKeyDown={(e) =>
                      e.key === "Enter" && setSearch(searchLoc.current.value)
                    }
                    placeholder={"Ricerca per parola chiave"}
                    ref={searchLoc}
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setSearch(searchLoc.current.value);
                    }}
                  >
                    <Lens />
                  </div>
                </SearchWrapper>
                <TabContainer>
                  <Tab $isActive={!onlyMine} onClick={() => setOnlyMine(false)}>
                    Tutte le associazioni
                  </Tab>
                  <Tab $isActive={onlyMine} onClick={() => setOnlyMine(true)}>
                    Create da me
                  </Tab>
                </TabContainer>
                {isLoading && (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      marginBottom: "48px"
                    }}
                  >
                    <Loader
                      type="Oval"
                      color="#B40000"
                      height={32}
                      width={32}
                    />
                  </div>
                )}
                {!isLoading &&
                  results?.data.map((result) => (
                    <div
                      key={result.id}
                      onClick={() => openAssociation(result.id)}
                    >
                      <AssociationWrapper key={result.id + "wrapper"}>
                        <AssociationName key={result.id + "name"}>
                          {result.name}
                        </AssociationName>
                        <AssociationPlace key={result.id + "place"}>
                          {result.place?.type === "local"
                            ? result.place.city
                            : result.place?.comune}
                        </AssociationPlace>
                        <RedDots key={result.id + "red"} />
                        <NextDivisor key={result.id + "div"} />
                      </AssociationWrapper>
                    </div>
                  ))}
                  {!isLoading && results?.data.length === 0 && (
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      Nessuna associazione trovata
                    </div>
                  )}
              </Wrapper>
              {!isLoading && results && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
              >
                <ButtonsWrapper>
                  {page > 1 && (
                    <div onClick={() => firstPage()}>
                      <BackToFirst />
                    </div>
                  )}
                  {page > 1 && (
                    <div onClick={() => previousPage()}>
                      <Back />
                    </div>
                  )}
                  <Paging page={page} results={results.last_page} />
                  {page < results.last_page && (
                    <div onClick={() => nextPage()}>
                      <Next />
                    </div>
                  )}
                  {page < results.last_page && (
                    <div onClick={() => lastPage()}>
                      <NextToLast />
                    </div>
                  )}
                  </ButtonsWrapper>
                </div>
              )}
            </>
          }
        ></Container>
        <AddButton onClick={() => history.push("/addassociation")}>
          + Aggiungi Associazione
        </AddButton>
      </Body>
    </>
  );
}

export default AssociationList;
